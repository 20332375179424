import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { getCreateTotalAmount } from '../../api/checkout/checkout'
import { useRecoilState } from 'recoil'
import {
  activeRequestState,
  promoState,
  quantityState,
  selectedProductAddOnsState,
  selectedProductState,
} from '../../common/atoms/checkout'
import { TotalAmountResponse } from '../../types/checkout/responses/total-amount.response'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useInGameAccounts } from '../accounts/useInGameAccounts'
import { ign, server } from '../../common/utils/profile.utils'
import { activeTeamState, defaultCurrencyState } from '../../common/atoms/atoms'
import { isNil } from '../../common/utils/common.utils'
import { useGetProductLocalizedPrices } from './useGetProductLocalizedPrices'
import { useUser } from '../users/useUser'

export function useCreateTotalAmount(
  defaultQuantity?: number,
  isRematch = false,
) {
  const { data: prices, isFetched, isFetching } = useGetProductLocalizedPrices()
  const { data, status } = useSession()
  const [promoCode] = useRecoilState(promoState)
  const [quantity] = useRecoilState(quantityState)
  const { profiles } = useInGameAccounts()
  const [activeRequest] = useRecoilState(activeRequestState)
  const [currency] = useRecoilState(defaultCurrencyState)
  const [team] = useRecoilState(activeTeamState)
  const [selectedProduct] = useRecoilState(selectedProductState)
  const [selectedAddOns] = useRecoilState(selectedProductAddOnsState)
  const { user } = useUser()

  return useQuery<TotalAmountResponse>(
    [
      'total-amount',
      activeRequest?.orderItem?.product?.id ??
        team?.product.id ??
        selectedProduct?.id ??
        0,
      defaultQuantity ?? quantity ?? 1,
      status,
      promoCode?.code,
      server(profiles),
      ign(profiles),
      prices
        ?.map((product) => `${product.productId}-${product.charge[0]?.price}`)
        ?.join(','),
      activeRequest?.transactionSnapshot?.addonIds?.join(',') ??
        team?.addons?.map((addon) => addon.id)?.join(',') ??
        selectedAddOns?.map((addon) => addon.id)?.join(','),
      activeRequest?.id,
      currency?.id,
      !isRematch
        ? activeRequest
          ? undefined
          : team?.id
        : activeRequest?.teamId ?? team?.id,
      selectedProduct?.streamer?.id,
      user?.enableVoiceChat,
    ],
    async () => {
      //@ts-ignore
      return getCreateTotalAmount(data?.accessToken, {
        productId:
          activeRequest?.orderItem?.product?.id ??
          team?.product.id ??
          selectedProduct?.id ??
          0,
        promoCode: promoCode?.code,
        quantity: defaultQuantity ?? quantity ?? 1,
        server: server(profiles),
        ign: ign(profiles),
        addonIds:
          // For keep_playing order, using the same addonIds as the parent request.
          activeRequest?.transactionSnapshot?.addonIds ??
          team?.addons?.map((addon) => addon.id) ??
          selectedAddOns?.map((addon) => addon.id),
        streamer: selectedProduct?.streamer,
        currencyId: currency?.id,
        teamId: !isRematch
          ? activeRequest
            ? undefined
            : team?.id
          : activeRequest?.teamId ?? team?.id,
      })
    },
    {
      enabled:
        isFetched &&
        !isFetching &&
        !isNil(currency) &&
        (!isNil(activeRequest) || !isNil(team) || !isNil(selectedProduct)) &&
        (status === SessionStatusEnum.AUTHENTICATED ||
          status === SessionStatusEnum.UNAUTHENTICATED),
    },
  )
}
