import React, { useEffect } from 'react'
import Image from 'next/image'
import FreeGameGiftIcon from '../../public/static/discount/icon_free_game_gift.svg'
import { TotalAmountResponse } from '../../types/checkout/responses/total-amount.response'
import { useRecoilState } from 'recoil'
import { freeGameState } from '../../common/atoms/checkout'

interface Props {
  total?: TotalAmountResponse
}
export default function FreeGameRemaining({ total: freegame }: Props) {
  const [total, setTotal] = useRecoilState(freeGameState)

  useEffect(() => {
    if (freegame) {
      setTotal(freegame)
    }
  }, [freegame])

  if (
    (total && total?.freeGames < 1) ||
    total?.freeGames === undefined ||
    total?.freeGames === null
  ) {
    return null
  }

  return (
    <div
      className={
        'flex flex-row justify-between items-center rounded-lg bg-veigar-800 px-4 py-3.5 w-full'
      }
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div className={'flex flex-row items-center gap-4'}>
        <div className={'relative h-10 w-10'}>
          <Image
            src={FreeGameGiftIcon}
            className={'object-contain'}
            fill={true}
            alt={'FreeGameGift icon'}
          />
        </div>

        <p className={'text-white text-body-medium'}>Free games remaining:</p>
      </div>

      <div
        className={
          'flex flex-row items-center justify-center h-8 w-8 text-white rounded-full bg-veigar-700 text-body-medium'
        }
      >
        {total?.freeGames ?? 0}
      </div>
    </div>
  )
}
